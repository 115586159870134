import { useRouter } from 'vue-router'
import { ROUTES_NAMES } from '@/common/routes'

export const useGoHome = () => {
  const router = useRouter()

  const goHome = () => {
    return router.push({
      name: ROUTES_NAMES.ABOUT
    })
  }

  return {
    goHome
  }
}
