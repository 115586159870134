import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useScroll } from '@/utils/useScroll'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import { ROUTES_NAMES } from '@/common/routes'
import { useStore } from 'vuex'

const HEADER_THEMES = {
  DARK: 'dark',
  WHITE: 'white'
}

export const useHeaderTheme = () => {
  const store = useStore()
  const route = useRoute()
  const { scrollPosition } = useScroll()
  const { isMobile } = useBreakpointsMedia()

  const isShowMenu = computed({
    get: () => store.getters['headerModule/getIsShowMenu'],
    set: val => {
      store.commit('headerModule/SET_IS_SHOW_MENU', val)
    }
  })

  const isTop = computed(() => {
    return scrollPosition.value < 50 && isMobile.value
  })
  const headerTheme = computed(() => {
    if (isTop.value && !isShowMenu.value && route.name === ROUTES_NAMES.ABOUT) {
      return HEADER_THEMES.WHITE
    }
    return HEADER_THEMES.DARK
  })

  return {
    isTop,
    isMobile,
    isShowMenu,
    headerTheme,
    HEADER_THEMES,
    scrollPosition
  }
}
