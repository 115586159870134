<template>
  <div
    class="header-menu-toggle"
    :class="{
      'header-menu-toggle__close': isClose,
      'header-menu-toggle__white': isWhite
    }"
  >
    <div />
    <div />
    <div />
  </div>
</template>

<script>
export default {
  name: 'HeaderMenuToggle',
  props: {
    isWhite: {
      type: Boolean,
      default: false
    },
    isClose: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.header-menu-toggle {
  div {
    width: 26px;
    height: 2px;
    background-color: #2B2C30;
    margin-bottom: 6px;
    transition: transform 100ms linear;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &__white {
    div {
      background-color: white;
    }
  }
  &__close {
    div {
      display: none;
      margin: 0;

      &:first-of-type {
        display: block;
        transform: rotate(45deg) translateX(1px);
      }
      &:last-of-type {
        display: block;
        transform: rotate(-45deg) translateX(1px);
      }
    }
  }
}
</style>
