<template>
  <footer class="footer">
    <Text class="footer__title">
      Feral Technologies FZCO
    </Text>
    <div class="footer__location">
      <Icon name="uae"/>
      <Text>United Arab Emirates</Text>
    </div>
    <div class="footer__hr"/>
    <div class="footer__social">
      <div
        class="icon-wrapper"
        v-for="item in socialList"
        :key="item.key"
        @click="onSocialClick(item)"
      >
        <Icon
          :name="item.icon"
          :height="isMobile ? '18px' : '24px' "
          :width="isMobile ? '18px' : '24px' "
        />
      </div>
    </div>
  </footer>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
import Icon from '@/components/UIKit/Icon'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import { openLink } from '@/utils/openLink'
export default {
  name: 'Footer',
  components: {
    Icon,
    Text
  },
  setup () {
    const { isMobile } = useBreakpointsMedia()
    const socialList = [
      {
        key: 'twitter',
        href: 'https://twitter.com/frltech',
        icon: 'twitter'
      },
      {
        key: 'instagram',
        href: 'https://instagram.com/frltech',
        icon: 'instagram'
      },
      {
        key: 'linkedin',
        href: 'https://linkedin.com/company/frltech',
        icon: 'linkedin'
      }
    ]

    const onSocialClick = item => {
      openLink(item.href)
    }

    return {
      isMobile,
      socialList,
      onSocialClick
    }
  }
}
</script>

<style lang="scss">
$width: 248px;

.footer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 45px 0 57px 0;

  &__title {
    display: block;
    font-weight: 700;
    font-size: 24px;
    line-height: 144.63%;
    margin-bottom: 9px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  &__location {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    font-size: 14px;
    line-height: 144.63%;

    .icon {
      margin-top: -5px;
      margin-right: 9px;
    }
  }
  &__hr {
    width: $width;
    height: 1px;
    margin-bottom: 18px;
    background: #FFFFFF;
    opacity: 0.1;
    border-radius: 15px;
  }
  &__social {
    display: flex;
    justify-content: space-between;
    width: 270px;
  }
}

.icon-wrapper {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    transform: scale(0);
    transition: transform 0.2s;
  }

  .icon {
    svg path {
      fill: #ffffff;
      transition: fill 0.2s;
    }
  }

  &:hover {
    width: 60px;
    height: 60px;
    cursor: pointer;
    .icon {
      svg path {
        fill: #D48343;
      }
    }
    &::before {
      transform: scale(1);
    }
  }
}
</style>
