<template>
  <header
    class="mobile-header"
    :class="{
      'mobile-header__top': isTop && !isShowMenu,
      'mobile-header__dark': headerTheme === HEADER_THEMES.DARK,
      'mobile-header__white': headerTheme === HEADER_THEMES.WHITE
    }"
  >
    <div class="mobile-header__logo">
      <Icon
        :name="headerLogo"
        @click="goHome"
      />
    </div>
    <HeaderMenu v-model:is-show-menu="isShowMenu" />
  </header>
</template>

<script>
import { computed } from 'vue'
import Icon from '@/components/UIKit/Icon'
import HeaderMenu from '@/components/Header/HeaderMenu'
import { useHeaderTheme } from '@/components/Header/useHeaderTheme'
import { useGoHome } from '@/utils/useGoHome'

export default {
  name: 'MobileHeader',
  components: {
    Icon,
    HeaderMenu
  },
  setup () {
    const {
      isTop,
      isShowMenu,
      headerTheme,
      HEADER_THEMES
    } = useHeaderTheme()
    const {
      goHome
    } = useGoHome()

    const headerLogo = computed(() => {
      if (headerTheme.value === HEADER_THEMES.WHITE) {
        return 'full-logo-white-mobile'
      }
      return 'full-logo-mobile'
    })

    return {
      isTop,
      isShowMenu,
      headerLogo,
      headerTheme,
      HEADER_THEMES,
      goHome
    }
  }
}
</script>

<style lang="scss">
.mobile-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-get($mobileLayout, "HeaderHeight");
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: background-color 100ms linear;

  &__dark {
    border-color: rgba(0, 0, 0, 0.1);

    .header-menu__toggle {
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
  &__white {
    border-color: rgba(255, 255, 255, 0.1);

    .header-menu__toggle {
      border-color: rgba(255, 255, 255, 0.1);
    }
  }
  &__top {
    box-shadow: none;
    background-color: transparent;
    background-image: none;

    &.mobile-header__white .mobile-header__logo {
      margin-left: 18px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 20px;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      margin-right: 7px;

      cursor: pointer;
    }
  }
}
</style>
