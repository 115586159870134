import { onBeforeUnmount, onMounted, ref } from 'vue'

export const useScroll = (onScroll = () => {}, element = window) => {
  const scrollPosition = ref(null)

  onMounted(() => {
    element.addEventListener('scroll', updateScroll)
  })

  onBeforeUnmount(() => {
    element.removeEventListener('scroll', updateScroll)
  })

  const updateScroll = () => {
    scrollPosition.value = element.scrollY
    onScroll()
  }

  return {
    scrollPosition
  }
}
