import { useRoute, useRouter } from 'vue-router'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import { computed, reactive, ref } from 'vue'
import { ROUTES_NAMES } from '@/common/routes'
import { writeEmail } from '@/utils/writeEmail'

export const useHeader = () => {
  const route = useRoute()
  const router = useRouter()
  const { breakpoints } = useBreakpointsMedia()

  const contactEmail = ref('hello@feral.com')
  const isShowEmail = ref(false)
  const routes = reactive([
    {
      name: ROUTES_NAMES.ABOUT,
      title: 'About'
    },
    {
      name: ROUTES_NAMES.PORTFOLIO,
      title: 'Portfolio'
    }
  ])

  const activeRoute = computed(() => route.name)

  const linkClick = item => {
    if (item.name) {
      router.push({
        name: item.name
      })
    }
  }
  const toggleShowEmail = flag => {
    isShowEmail.value = flag
  }
  const onContactSupport = () => {
    writeEmail('hello@feral.com')
  }

  return {
    routes,
    breakpoints,
    isShowEmail,
    activeRoute,
    contactEmail,
    linkClick,
    toggleShowEmail,
    onContactSupport
  }
}
