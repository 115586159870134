<template>
  <div class="header-menu">
    <Button
      type="white"
      class="header-menu__toggle"
      @click="onMenuClick"
    >
      <HeaderMenuToggle
        :is-close="isShowMenu"
        :is-white="headerTheme === HEADER_THEMES.WHITE"
      />
    </Button>
    <div
      class="header-menu__body"
      :class="{
        'header-menu__body_active': isShowMenu
      }"
    >
      <MobileHeaderMenuBackground />
      <div class="header-menu__body-links">
        <Button
          v-for="item in routes"
          :key="item.name"
          type="white"
          class="header-menu__link"
          @click="linkClick(item)"
        >
          <Text weight="700" size="10.35vw" line-height="13vw">{{ item.title }}</Text>
        </Button>
        <Button
          class="header-menu__link"
          type="white"
          @click="onContactSupport"
          @mouseover="toggleShowEmail(true)"
          @mouseleave="toggleShowEmail(false)"
        >
          <Text weight="700" size="10.35vw" line-height="13vw">{{ isShowEmail ? contactEmail : 'Contact' }}</Text>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Text from '@/components/UIKit/Text/Text'
import Button from '@/components/UIKit/Button'
import MobileHeaderMenuBackground from '@/components/Header/MobileHeaderMenuBackground'
import { useHeader } from '@/components/Header/useHeader'
import { useBreakpointsMedia } from '@/plugins/breakpoints'
import HeaderMenuToggle from '@/components/Header/HeaderMenuToggle'
import { useHeaderTheme } from '@/components/Header/useHeaderTheme'
import { watch } from '@vue/runtime-core'

export default {
  name: 'HeaderMenu',
  components: {
    HeaderMenuToggle,
    Text,
    Button,
    MobileHeaderMenuBackground
  },
  setup () {
    const {
      isShowMenu,
      headerTheme,
      HEADER_THEMES
    } = useHeaderTheme()
    const {
      routes,
      isShowEmail,
      contactEmail,
      linkClick: onLinkClick,
      toggleShowEmail,
      onContactSupport: contactSupport
    } = useHeader()
    const { isTablet } = useBreakpointsMedia()

    watch(isShowMenu, nv => {
      setScroll(!nv)
    })

    const onMenuClick = () => {
      if (isShowMenu.value) {
        onHideMenu()
      } else {
        onShowMenu()
      }
    }
    const setScroll = flag => {
      if (flag) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
    }
    const onShowMenu = () => {
      isShowMenu.value = true
    }
    const onHideMenu = () => {
      isShowMenu.value = false
    }
    const linkClick = link => {
      onLinkClick(link)
      onHideMenu()
    }

    return {
      routes,
      isTablet,
      isShowMenu,
      isShowEmail,
      headerTheme,
      contactEmail,
      HEADER_THEMES,
      linkClick,
      onHideMenu,
      onShowMenu,
      onMenuClick,
      toggleShowEmail,
      onContactSupport: contactSupport
    }
  }
}
</script>

<style lang="scss">
.header-menu {
  display: flex;
  align-items: center;

  .mobile-header-menu-background {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 95%;
    width: 20%;
  }
  &__toggle {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 21px 22px !important;
    background-color: transparent !important;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0;
  }
  &__body {
    position: fixed;
    top: map-get($mobileLayout, "HeaderHeight");
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    background-color: white;
    padding: 32px 0 0 10vw;
    transition: opacity 200ms linear;
    display: none;

    &__body-links {
      margin-top: 200px;
      transition: margin-top 500ms linear;
    }
    &_active {
      opacity: 1;
      display: block;

      .header-menu__body-links {
        padding-top: 0;
      }
    }

    &-close {
      position: absolute;
      top: 20px;
      right: 20px;
      border: none;
    }
  }
  &__link {
    display: block;
    margin-top: 11.5vh;
    margin-bottom: 60px;
    padding: 0;
  }
}
</style>
